import React from 'react'
import '../css/Utility.css'
import mainlogo from '../assets/img/main_logo.svg'
export default function Navbar() {
    return (
        <>
        {/* <div className=''> */}

            <nav className='bg-white w-full fixed font-sans' >
                <div className='container mx-auto flex h-20 justify-between items-center'>
                    <button className='border font-semibold rounded-3xl text-sm p-2 px-6 sm:px-8 sm:py-2 sm:mx-0 mx-5'>Menu</button>
                    <img src={mainlogo} alt='logo' className='h-6 sm:h-12' />
                    <a href="#contactUs"><button className='bg-peachPuff font-semibold rounded-3xl text-sm p-2 sm:px-8 sm:py-2 sm:mx-0 mx-5'>Get in Touch</button></a>
                </div>
            </nav>
        {/* </div> */}
        </>
    )
}
