import React from 'react'
import Navbar from './Navbar'
import { useNavigate } from 'react-router'
import '../css/PageNotFound.css'

export default function PageNotFound() {

    const navigate = useNavigate()
  return (
    <>
    <Navbar/>
    <div>
        {/* LANDING IMAGE */}
        <div className='PageNotFound text-white flex justify-center items-center'>
          {/* <img src={landingPage} alt="Landing page" className='landingpage absolute'/> */}
          <div className='PageNotFound-heading text-center'>
            <p className='md:text-7xl xl:text-9xl text-5xl font-semibold'>404</p>
            <p className='mt-3 mb-7 md:fontSize-3 text-3xl font-semibold '>Something is wrong</p>
            <p className='mt-3 mb-7 text-xl'>Sorry, it seems we're having trouble connecting to the server at the moment</p>
            <button className=' px-8 py-2 me-5 text-black bg-white font-bold rounded-lg border' onClick={()=>{navigate('/')}}>GO HOME</button>
          </div>
        </div>
        </div>
    </>
  )
}
