import React from 'react'
import Navbar from './Navbar'
import { useNavigate } from 'react-router'
import '../css/PatentDetail.css'

export default function PatentDetail() {

    const navigate = useNavigate()
  return (
    <>
    <Navbar/>
    <div>
        {/* LANDING IMAGE */}
        <div className='PatentDetail text-white flex justify-center items-center'>
          {/* <img src={landingPage} alt="Landing page" className='landingpage absolute'/> */}
          <div className='PatentDetail-heading text-center'>
            <p className='md:text-5xl xl:text-7xl text-3xl font-semibold'>Work in Progress</p>
            <p className='mt-3 mb-7 text-lg font-semibold '>Our patent are under filing now, we will soon be live.</p>
            <button className='border bg-peachPuff rounded-3xl px-8 py-2 text-black sm:me-5' onClick={()=>{navigate('/')}}>GO HOME</button>
            <button className='border rounded-3xl px-8 py-2'>Get in touch</button>
          </div>
        </div>
        </div>
    </>
  )
}
