import React, { useState } from 'react';
import '../css/Utility.css';
import '../css/Home.css';
import Navbar from './Navbar';
import aboutUs_1 from '../assets/img/aboutUs_1.png';
import aboutUs_2 from '../assets/img/aboutUs_2.png';
import aboutUs_3 from '../assets/img/aboutUs_3.png';
import arrow_up from '../assets/img/arrow-up.png';
import arrow_down from '../assets/img/arrow-down.png';
import Footer from './Footer';
import { db } from '../firebase-config';
import { collection,getDoc,getDocs,addDoc,doc } from 'firebase/firestore';

export default function Home() {
  const [showAns1, setShowAns1] = useState(false)
  const [showAns2, setShowAns2] = useState(false)
  const [showAns3, setShowAns3] = useState(false)
  const [showAns4, setShowAns4] = useState(false)

  const [name, setName] = useState("")
  const [lastname, setLastname] = useState("")
  const [email, setEmail] = useState("")
  const [number, setNumber] = useState("")
  const [question, setquestion] = useState("")



  // const encodedMessage = "Hello";
  const phoneNumber = 5550126;
  const whatsappUrl = `https://wa.me/${phoneNumber}`;
  const zeroDynamicsRef = collection(db,"zero_dynamics")
  const handleSubmit =()=>{
    console.log(name, lastname, email, number, question)
    const data = {
      name, lastname, email, number, question
    }
    addDoc(zeroDynamicsRef,data)
    setName('')
    setLastname('')
    setEmail('')
    setNumber('')
    setquestion('')
  }

  return (
    <>
      <div className=''>
        <Navbar />
      </div>
      <div className='font-sans'>
        {/* LANDING IMAGE */}
        <div className='landingpage text-white flex justify-center items-center'>
          {/* <img src={landingPage} alt="Landing page" className='landingpage absolute'/> */}
          <div className='landingPage-heading text-center'>
            <p className='md:text-5xl xl:text-7xl text-3xl font-semibold'>Empowering</p>
            <p className=' mb-7 mt-2 md:text-5xl xl:text-7xl text-3xl font-semibold '>Innovation Globally</p>
            <a href="#aboutUs"><button className='bg-peachPuff rounded-3xl px-8 py-2 text-black sm:me-5 me-2'>What we do</button></a>
            <a href="#contactUs"><button className='border rounded-3xl px-8 py-2'>Get in touch</button></a>
          </div>
        </div>

        <div id='aboutUs'></div>

        {/* ABOUT US */}
        <div className='mt-32'>
          <div className="sm:container mx-auto">
            {/* about us details */}
            <div className="container lg:px-28 ">
              <h2 className='text-center mb-3 t-purple font-bold text-lg tracking-[.25em] '>ABOUT US</h2>
              <p className='sm:text-xl md:text-2xl text-base 2xl:px-72 xl:px-40 lg:px-10 md:px-5 px-5 text-center'>
                “<span className='t-purple font-semibold'>Zero Dynamics</span> is a research and development based company with the founding  principle of supporting innovators, researchers, and companies globally by providing  them access to a wide range of efficient  and effective intellectual properties!”
              </p>
            </div>
            {/* about us cards */}
            <div className='sm:flex mt-16 mx-5'>
              <div className=' mb-10'>
                <img src={aboutUs_1} alt="" />
                <div className='text-center mt-5'>
                  <p className='text-2xl font-semibold'>Patent</p>
                  <p className='text-base mt-3 text-slate-600'>A collection of patented technologies spanning various industries.</p>
                </div>
              </div>
              <div className='md:mx-10 sm:mx-2  mb-10'>
                <img src={aboutUs_2} alt="" className='' />
                <div className='text-center mt-5'>
                  <p className='text-2xl font-semibold'>Licensing Opportunities</p>
                  <p className='text-base mt-3 text-slate-600'>Offering licenses for the use of proprietary technologies, allowing companies to incorporate innovative solutions into their products or services.</p>
                </div>
              </div>
              <div className=' mb-10'>
                <img src={aboutUs_3} alt="" />
                <div className='text-center mt-5'>
                  <p className='text-2xl font-semibold'>Assisted Development</p>
                  <p className='text-base mt-3 text-slate-600'>Access to research materials, CAD Schematics, EDA Schematics, and UML Charts for concept-to-product lifecycle.</p>
                </div>
              </div>
            </div>
          </div>

        </div>

        {/* FREQUENTLY ASKED QUESTION */}
        <div className='sm:py-24 py-10 mt-20 flex justify-center bg-lightPink' >
          <div className="container ">

            <div className='text-center '>
              <p className='mb-2 t-purple font-bold tracking-[.25em]'>FAQ</p>
              <p className='sm:text-5xl text-3xl font-semibold t-primary'>Frequently Asked Questions</p>
            </div>
            <div className='mt-12 lg:mx-40 sm:mx-10 mx-5 flex flex-col items-center '>
              <div className='sm:px-14 w-full mt-10'>
                <p className='text-lg font-semibold mb-3 flex t-primary' onClick={() => { setShowAns1(!showAns1) }}>What industries are the main focus of Zero Dynamics? <img src={showAns1 ? arrow_up : arrow_down} alt="" className='ms-auto h-7' /></p>
                <p className='font-semibold text-slate-500 lg:w-3/5' hidden={!showAns1}>Our ever-expending catalogue of IPs will continue to cover a wide range of industries. We have no plans to constraint our innovations to some specific industries.</p>
              </div>
              <div className='sm:px-14 w-full mt-10'>
                <p className='text-lg font-semibold mb-3 flex t-primary' onClick={() => { setShowAns2(!showAns2) }}>Can individuals or small businesses benefit from your services? <img src={showAns2 ? arrow_up : arrow_down} alt="" className='ms-auto h-7' /></p>
                <p className='font-semibold text-slate-500 lg:w-3/5' hidden={!showAns2}>Yes, our primary focus is to work with individuals, startups, and SMEs. Those who are facing competitive challenge against large corporations would benefit the most.</p>
              </div>
              <div className='sm:px-14 w-full mt-10'>
                <p className='text-lg font-semibold mb-3 flex t-primary' onClick={() => { setShowAns3(!showAns3) }}> How does Zero Dynamics ensure that the innovations are actually practical? <img src={showAns3 ? arrow_up : arrow_down} alt="" className='ms-auto h-7' /></p>
                <p className='font-semibold text-slate-500 lg:w-3/5' hidden={!showAns3}>We focus on the practicality of the technology rather than the overall product design. From material to mechanism, each aspect is thoroughly researched.</p>
              </div>
              <div className='sm:px-14 w-full mt-10' id='contactUs'>
                <p className='text-lg font-semibold mb-3 flex t-primary' onClick={() => { setShowAns4(!showAns4) }}>What can be expected in terms of licensing fees? <img src={showAns4 ? arrow_up : arrow_down} alt="" className='ms-auto h-7' /></p>
                <p className='font-semibold text-slate-500 lg:w-3/5' hidden={!showAns4}>The base non-exclusive licensing fees is set so that even clients in the developing countries can afford it. If the clients request a higher degree of exclusivity, the fees would vary.</p>
              </div>
            </div>
          </div>
        </div>

        {/* CONTACT US */}
        <div className='constactUs flex flex-col items-center sm:py-20 py-10 text-white  ' >
          <div className='container text-center  sm:px-0 px-5'>
            <p className='sm:text-lg text-base mb-2 t-purple font-semibold  tracking-[.25em]' >CONTACT US</p>
            <p className='sm:text-5xl py-1 text-2xl font-semibold sm:mb-4 mb-4'>We'd love to hear from you!</p>
            <p className=''>Let's get in touch</p>
          </div>
          <div className='container sm:flex sm:mt-16 mt-7 justify-center items-center '>

            {/* MAIL AND OTHER CONTACT INFO */}
            <div className='sm:w-full sm:text-xl md:ps-20 mx-5 sm:mx-0'>
              <div className=''>
                <div className='pb-5 border-b border-slate-700'>
                  <p className=''>Mail :</p>
                  <p className='sm:text-lg mt-2 flex item-center'><i className="bi bi-envelope sm:text-2xl"></i>&nbsp;support@zerodynamics.com</p>
                </div>
                <div className='mt-5 '>
                  <p>Call :</p>
                  <p className='sm:text-lg mt-2 flex item-center'><i className="bi bi-telephone sm:text-2xl"></i>&nbsp;(252) 555-0126</p>
                  <button className='mt-3 me-3 sm:px-12 px-5 sm:py-2 py-1 inline rounded-3xl bg-white text-black '><a href="tel:+252-555-0126">Call</a></button>
                  <a href={whatsappUrl} target="_blank" rel="noopener noreferrer"><button className='md:px-4 sm:px-5 px-3 sm:py-2 py-1 inline rounded-3xl bg-green-600 '>Whatsapp</button></a>
                  <div className='pb-8 mt-8 border-b border-slate-700 '>
                    <p className='inline sm:text-xl text-sm p-2 bg-white/20 rounded-lg'>11.53am, Wednesday, 8 May 2024 (local IST)</p>
                  </div>
                </div>
              </div>
            </div>

            {/* SUPPORT FORM */}
            <div className='w-full p-5 lg:pe-20  '>
              <div className='rounded-3xl sm:py-5 py-3 sm:px-10 px-5  bg-lightPink text-black'>
                <div className='lg:flex'>
                  <div className='w-full xl:pe-10 lg:text-base'>
                    <p className='font-semibold'>First Name<span className='text-red-500'>*</span></p>
                    <input type="text" className='border w-full rounded mt-2 p-2' placeholder='John'
                    onChange={(e)=>{setName(e.target.value)}}
                    value={name}
                    />
                  </div>
                  <div className='lg:mt-0 mt-5 w-full lg:text-base'>
                    <p className='font-semibold'>Last Name<span className='text-red-500'>*</span></p>
                    <input type="text" className='border w-full rounded sm:mt-2 p-2' placeholder='Smith' 
                    onChange={(e)=>{setLastname(e.target.value)}}
                    value={lastname}
                    />
                  </div>
                </div>
                <div className='mt-5 w-full lg:text-base'>
                  <p className='font-semibold'>Email Address<span className='text-red-500'>*</span></p>
                  <input type="email" className='border w-full rounded sm:mt-2 p-2' placeholder='johnsmith@dpo.com' 
                  onChange={(e)=>{setEmail(e.target.value)}}
                  value={email}
                  />
                </div>
                <div className='mt-5 w-full  md:text-base'>
                  <p className='font-semibold'>Contact Number</p>
                  <input type="number" className='border w-full rounded sm:mt-2 p-2' placeholder='Please enter Your Number' 
                  onChange={(e)=>{setNumber(e.target.value)}}
                  value={number}
                  />
                </div>
                <div className='mt-5 w-full  md:text-base'>
                  <p className='font-semibold'>What do you need help with?</p>
                  <input type="text" className='border w-full rounded sm:mt-2 p-2' placeholder='Write something...' 
                  onChange={(e)=>{setquestion(e.target.value)}}
                  value={question}
                  />
                </div>
                <p className='sm:mt-7 mt-3 font-semibold text-sm text-center'>You will NOT receive unnecessary notifications and updates from ZERO DYNAMICS.</p>
                <div className=''>
                  <button className='border w-full mt-8 p-3 text-xl bg-mediumPurple text-white rounded-3xl font-bold' 
                  onClick={()=>{handleSubmit()}}
                  disabled={name===''||lastname===''||email===''||number===''||question===''}
                  >Submit</button>
                </div>
              </div>

            </div>
          </div>

        </div>

        <Footer />

      </div>
    </>
  )
}
