import { Route, Routes } from "react-router-dom";
import Home from './component/Home'
import PatentDetail from "./component/PatentDetail";
import PageNotFound from "./component/PageNotFound";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/PatentDetail" Component={PatentDetail} />
        <Route path="/PageNotFound" Component={PageNotFound} />
      </Routes>
    </>
  );
}

export default App;
