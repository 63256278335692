import { initializeApp } from "firebase/app";
import { Firestore, getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCTQA5SK2w5fsXGX_JCPMntzr-E_MLNU2A",
  authDomain: "zero-dynamics-82120.firebaseapp.com",
  projectId: "zero-dynamics-82120",
  storageBucket: "zero-dynamics-82120.appspot.com",
  messagingSenderId: "95303658715",
  appId: "1:95303658715:web:b7ea780e4174056b73b0fe"
};

const app = initializeApp(firebaseConfig);
 
export const db =  getFirestore(app)