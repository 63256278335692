import React from 'react'
import white_mainLogo from '../assets/img/white_mainLogo.png'
import facebook from '../assets/img/facebook.png';

export default function Footer() {
    return (
        <>
            {/* FOOTER */}
            <footer className='bg-darkPurple footer flex justify-center py-20 font-sans'>

                <div className='sm:container mx-5 sm:mx-0'>
                    <div className=''>
                        <img src={white_mainLogo} alt="" className='sm:h-28 h-16' />
                    </div>
                    <div className='sm:flex mt-3 items-center text-white border-b border-purple-900'>
                        <div className='w-full mx-auto flex justify-between sm:p-5'>
                            <span className='sm:me-2'>Home</span>
                            <span className='sm:me-2'>Catelogs</span>
                            <span className='sm:me-2'>About Us</span>
                            <span className='sm:me-2'>FAQs</span>
                            <span className='sm:me-2'>Contact Us</span>
                        </div>
                        <div className='w-full flex sm:justify-end items-center  sm:p-5 sm:py-10 py-8'>
                            <i className="bi bi-twitter text-2xl mx-1 bg-purple rounded-3xl p-2 py-1"></i>
                            <i className="bi bi-twitter text-2xl mx-1 bg-purple rounded-3xl p-2 py-1"></i>
                            <i className="bi bi-twitter text-2xl mx-1 bg-purple rounded-3xl p-2 py-1"></i>
                            <img src={facebook} alt="" className='bg-purple ms-1 h-10 rounded-3xl p-1'/>
                        </div>
                    </div>
                    <p className='text-center text-white mt-10'>Copyright © 2006-2024 Zero Dynamics. All rights reserved.</p>
                </div>

            </footer>
        </>
    )
}
